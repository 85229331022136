import React from 'react'
import { EmptyArticle as EA } from '@qlue/icon-kit'
import {
  ThumbnailItem, 
  EmptyIconWrapper
} from './styles'
import { FormattedMessage } from 'utils/intl'

const EmptyArticle = () => (
  <EmptyIconWrapper>
    <div>
      <EA fill="grey" />
      <ThumbnailItem.Description>
        <FormattedMessage id="07224d608" />
      </ThumbnailItem.Description>
    </div>
  </EmptyIconWrapper>
)

export default EmptyArticle
