import React, { useEffect, useState, useCallback } from 'react'
// import dayjs from "dayjs";
import API, { BlogURL } from 'commons/API'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  WrapperHeader, 
  TitleSection, 
  ContentItem, 
  NewsItem, 
  Line
} from './styles'
import EmptyArticle from './EmptyArticle'
import { FormattedMessage } from 'utils/intl'

const NewsSection = ({ articles }) => {
  const [news, setNews] = useState(articles)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(2)

  const fetchNews = useCallback((page) => {
    API.get(`${BlogURL.GetBlog}?page=${page}&per_page=7`)
      .then((res) => {
        let data
        if(page === 1) {
          data = res.data.contents.slice(4)
          const totalPage = res.data.meta.total_page
          setNews(data)
          setTotalPage(totalPage)
          
        }
        else {
          data = res.data.contents
          setNews([...news, ...data])
        }
        
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    fetchNews(page)
  }, [fetchNews, page])

  return(
    <>
      <WrapperHeader mt="4rem">
        <TitleSection>
          <FormattedMessage id="4dd248d76" />
        </TitleSection>
        <Line />
      </WrapperHeader>
      <NewsArticle news={ news } page={ page } totalPage={ totalPage } setPage={ setPage } />
    </>
  )
}

const NewsArticle = ({ news, page, totalPage, setPage }) => {
  if (news.length === 0) {
    return <EmptyArticle />
  }
  return (
    <InfiniteScroll
      dataLength={ news.length }
      next={ () => setPage(page+1) }
      hasMore={ page < totalPage }
      loader={ <h4><FormattedMessage id="cc5ec061c" /></h4> }
    >
      <NewsItem>
        { 
          news.map((article, idx) => (
            <ContentItem key={ idx }>
              <img src={ article.thumbnail_photo } alt={ `${article.title} image` } />
              <ContentItem.Title to={ `/articles/${article.slug}` }>{ article.title }</ContentItem.Title>
              <ContentItem.Desc>{ article.description }</ContentItem.Desc>
              { /* <ContentItem.CreatedAt>
            {dayjs(article.created_at).format("MMM, D YYYY")}
          </ContentItem.CreatedAt> */ }
            </ContentItem>
          )) 
        }
      </NewsItem>
    </InfiniteScroll>
  )
}

export default NewsSection
