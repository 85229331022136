import React from 'react'
import EmptyArticle from './EmptyArticle'
import { WrapperHeader, TitleSection, ThumbnailItem, ContentItem, ListItem, Line } from './styles'
import { FormattedMessage } from 'utils/intl'

const UpdatesSection = (props) => (
  <>
    <WrapperHeader>
      <TitleSection>
        <FormattedMessage id="6582bd348" />
      </TitleSection>
      <Line />
    </WrapperHeader>
    <UpdatesArticle { ...props }  />
  </>
)

const UpdatesArticle = ({ firstArticle, articles }) => {
  if (articles.length === 0) {
    return <EmptyArticle />
  }
  return (
    <>
      <ThumbnailItem>
        <img src={ firstArticle.thumbnail_photo } alt={ `${firstArticle.title || ''} image` } />
        <ThumbnailItem.WrapperContent>
          <ThumbnailItem.Title to={ `/articles/${firstArticle.slug}` }>
            { firstArticle.title }
          </ThumbnailItem.Title>
          <ThumbnailItem.Description>{ firstArticle.description }</ThumbnailItem.Description>
          { /* <ThumbnailItem.CreatedAt>
          {dayjs(firstArticle.created_at).format("MMM, D YYYY")}
        </ThumbnailItem.CreatedAt> */ }
        </ThumbnailItem.WrapperContent>
      </ThumbnailItem>
      <ListItem>
        { articles.map((article, idx) => (
          <ContentItem key={ idx }>
            <ContentItem.Title to={ `/articles/${article.slug}` }>{ article.title }</ContentItem.Title>
            <ContentItem.Desc>{ article.description }</ContentItem.Desc>
            { /* <ContentItem.CreatedAt>
            {dayjs(article.created_at).format("MMM, D YYYY")}
          </ContentItem.CreatedAt> */ }
          </ContentItem>
        )) }
      </ListItem>
    </>
  )
}

export default UpdatesSection
