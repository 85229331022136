import React from 'react'

import SEO from 'components/seo'
import Layout from 'components/Layout'
import { Title, Wraper } from './styles'

import UpdatesSection from './UpdatesSection'
import NewsSection from './NewsSection'

import { FormattedMessage } from 'utils/intl'

const DESC = 'Read our thoughts about the technology implementations in industry.'

function BlogHomePage({ pageContext: { articles } }) {
  const firstArticle = articles[0] || {}
  const topArticles = articles.slice(1, 4)
  const theRestArticles = articles.slice(4)

  return (
    <Layout>
      <SEO title="Articles" description={ DESC } />
      <Wraper>
        <Title><FormattedMessage id="f1d9b39a0" /></Title>
        <UpdatesSection firstArticle={ firstArticle } articles={ topArticles } />
        <NewsSection articles={ theRestArticles } />
      </Wraper>
    </Layout>
  )
}

export default BlogHomePage
