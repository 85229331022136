import styled from 'styled-components'
import { Link } from 'gatsby'
import { darkBlueGrey, lightGrey, paleGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Title = styled.h1`
  font-size: 1.7rem;
  color: ${darkBlueGrey};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  margin: 0;

  ${IS_FRAME_TABLET} {
    margin-top: 1.5rem;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 1.4rem;
    margin-top: 0.75rem;
  }
`

export const Wraper = styled.div`
  padding: 3.5em 0;
  margin: 0 auto;
  width: calc(100vw - 30em);
  max-width: 1600px;

  ${IS_FRAME_TABLET} {
    width: unset;
    padding: 0 1.5rem;
  }

  ${IS_FRAME_MOBILE} {
    width: unset;
    padding: 0 1rem;
  }
`

export const WrapperHeader = styled.div`
  margin-top: ${({ mt }) => mt || '1.5rem'};
  margin-bottom: 1.6rem;

  ${IS_FRAME_TABLET} {
    margin-top: 1.5rem;
  }
`

export const TitleSection = styled.p`
  font-size: 1.3rem;
  color: ${darkBlueGrey};
  font-weight: bold;
  margin: 0;
`

export const Line = styled.hr`
  background-color: ${lightGrey};
  height: 1px;
  opacity: 0.3;
`

export const ThumbnailItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.7fr 1.2fr;
  grid-column-gap: 20px;
  grid-auto-rows: 26em;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${IS_FRAME_TABLET} {
      width: 100%;
      height: 100%;
    }

    ${IS_FRAME_MOBILE} {
      width: 100%;
      height: 8rem;
    }
  }

  ${IS_FRAME_TABLET} {
    grid-column-gap: 1.5rem;
    grid-auto-rows: 11rem;
    grid-template-columns: 0.8fr 1fr;
  }

  ${IS_FRAME_MOBILE} {
    grid-column-gap: 10px;
    grid-auto-rows: 11em;
    grid-template-columns: 0.8fr 1fr;
  }
`

ThumbnailItem.WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
`

ThumbnailItem.Title = styled(Link)`
  font-size: 1.5rem;
  color: ${darkBlueGrey};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 1.1rem;
  }
`

ThumbnailItem.Description = styled.p`
  font-size: 1rem;
  color: ${lightGrey};
  margin: 10px 0;

  ${IS_FRAME_MOBILE} {
    font-size: 0.9rem;
    margin: 5px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

ThumbnailItem.CreatedAt = styled.p`
  margin: 0;
  margin-top: 0.3em;
  font-size: 0.85rem;
  color: ${lightGrey};
  font-weight: 600;

  ${IS_FRAME_MOBILE} {
    margin-top: 3px;
  }
`

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.7rem;
  grid-gap: 30px;

  ${IS_FRAME_TABLET} {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
  }
`

export const NewsItem = styled(ListItem)`
  ${IS_FRAME_TABLET} {
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
`

export const ContentItem = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 8px;

  > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    position: relative;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url("https://indonesiapower.co.id/SiteAssets/image-not-found.png");
      background-size: cover;
      background-position: center;
    }
  }
`

ContentItem.Title = styled(ThumbnailItem.Title)`
  font-size: 1rem;

  ${IS_FRAME_MOBILE} {
    font-size: 1.1rem;
  }
`

ContentItem.Desc = styled(ThumbnailItem.Description)`
  font-size: 0.8rem;
  margin: 0;

  ${IS_FRAME_MOBILE} {
    font-size: 0.9rem;
    overflow: unset;
    display: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }
`

ContentItem.CreatedAt = styled(ThumbnailItem.CreatedAt)`
  font-size: 0.7rem;
  font-weight: bold;

  ${IS_FRAME_MOBILE} {
    font-size: 0.8rem;
  }
`

export const EmptyIconWrapper = styled.div`
  display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height:  50vh;
	background: ${paleGrey};
`